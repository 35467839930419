
























































import camelcaseKeys from 'camelcase-keys';
import Component, { mixins } from 'vue-class-component';
import { getAccountingSyncData } from '../api/goods-received-notes-api';
import GoodsReceivedNoteMixin from './goods-received-note-mixin.vue';

@Component
export default class extends mixins(GoodsReceivedNoteMixin) {
  goodsReceivedNoteId: Number = 0;

  accountingNoteFields: Array<any> = [
    {
      key: 'accountingNoteLineNumber',
      label: 'Nr. crt.',
      thClass: 'text-center tbl-col-idx',
      tdClass: 'text-center py-2 tbl-col-idx',
    },
    {
      key: 'accountingNoteLineDebitAccountNumber',
      label: 'Cont debitor',
      thClass: 'text-center tbl-col-idx',
      tdClass: 'text-center py-2 tbl-col-idx',
    },
    {
      key: 'accountingNoteLineCreditAccountNumber',
      label: 'Cont creditor',
      thClass: 'text-center tbl-col-idx',
      tdClass: 'text-center py-2 tbl-col-idx',
    },
    {
      key: 'accountingNoteValue',
      label: 'Suma (lei)',
      thClass: 'text-right tbl-col-idx',
      tdClass: 'text-right py-2 tbl-col-idx',
      formatter: (item) => this.decimal2PlacesFormatter(item),
    },
    {
      key: 'accountingNoteLineDetails',
      label: 'Explicație',
      thClass: 'text-left',
      tdClass: 'text-left py-2',
    },
  ];

  accountingNoteItems: Array<any> = [];
  accountingNoteDetails: any = null;

  onUnsyncAccounting() {
    this.$emit('onUnsyncAccounting');
  }

  async initialize() {
    this.accountingNoteDetails = null;
    this.accountingNoteItems = [];

    const accountingData = camelcaseKeys(await getAccountingSyncData(this.goodsReceivedNoteId), { deep: true });

    this.accountingNoteDetails = {
      ...accountingData.item1,
    };
    this.accountingNoteItems = accountingData.item2;
  }

  hideModal() {
    (<any>(this.$refs.accountingDataModal)).hide();
  }

  async showModal(goodsReceivedNoteId) {
    this.goodsReceivedNoteId = goodsReceivedNoteId;

    await this.initialize();

    (<any>(this.$refs.accountingDataModal)).show();
  }
}
