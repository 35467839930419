


















































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import debounce from 'lodash/debounce';
import parseJSON from 'date-fns/parseJSON';
import camelcaseKeys from 'camelcase-keys';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import startOfYear from 'date-fns/startOfYear';
import endOfYear from 'date-fns/endOfYear';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import addYears from 'date-fns/addYears';
import formatISO from 'date-fns/formatISO';

import i18n from '@/i18n';
import {
  getGoodsReceivedNotesList,
  getAvailableInventories,
  lookupSupplier,
  lookupSupplierById,
} from '../api/goods-received-notes-api';

import { formatNumber as fn } from '../../../utils/number-formatting';

@Component
export default class extends Vue {
  allInventoryOptions: Array<any> = [];
  inventoryOptions: Array<any> = [];
  isInventoryOptionsLoading = false;

  isSupplierOptionsLoading = false;
  supplierOptions = [];

  sessionStorage = window.sessionStorage;
  localStorage = window.localStorage;

  sourceDocumentTypeOptions = [
    { key: 'FAF', label: 'Fact.' },
    { key: 'FAV', label: 'Aviz' },
    { key: 'FSM', label: 'Fact. sim.' },
    { key: 'BON', label: 'Bon' },
    { key: 'OTH', label: 'Doc.' },
  ];

  statusOptions = [
    { status: 1, label: 'Procesată' },
    { status: 0, label: 'Ciornă' },
    { status: 2, label: 'Anulată' },
    { status: 3, label: 'Sincronzată' },
  ];

  formatSourceDocumentType(type) {
    return this.sourceDocumentTypeOptions.find((x) => x.key === type)?.label || 'Doc.';
  }

  formatNumber(input, dp) {
    return fn(input, dp);
  }

  dateInterval = [startOfMonth(new Date()), endOfMonth(new Date())];
  datepickerShortcuts = [{
    text: 'Astăzi',
    onClick: () => [startOfDay(new Date()), endOfDay(new Date())],
  },
  {
    text: 'Ieri',
    onClick: () => [addDays(startOfDay(new Date()), -1), addDays(endOfDay(new Date()), -1)],
  },
  {
    text: 'Luna curentă',
    onClick: () => [startOfMonth(new Date()), endOfMonth(new Date())],
  }, {
    text: 'Luna trecută',
    onClick: () => [addMonths(startOfMonth(new Date()), -1), endOfMonth(addMonths(new Date(), -1))],
  }, {
    text: 'Ultimele 3 luni',
    onClick: () => [addMonths(startOfMonth(new Date()), -2), endOfMonth(new Date())],
  }, {
    text: 'Ultimele 6 luni',
    onClick: () => [addMonths(startOfMonth(new Date()), -5), endOfMonth(new Date())],
  }, {
    text: 'Anul curent',
    onClick: () => [startOfYear(new Date()), endOfYear(new Date())],
  }, {
    text: 'Anul trecut',
    onClick: () => [addYears(startOfYear(new Date()), -1), addYears(endOfYear(new Date()), -1)],
  }];

  startDate = startOfMonth(new Date());
  endDate = endOfMonth(new Date());
  inventory: any|null = null;
  supplier: any|null = null;
  status: any|null = null;

  async asyncSearchSupplier(query) {
    if (!query || query.length < 2) {
      return Promise.resolve();
    }

    this.isSupplierOptionsLoading = true;

    this.supplierOptions = camelcaseKeys(await lookupSupplier(query));

    this.isSupplierOptionsLoading = false;
    return Promise.resolve();
  }

  addToGRNSessionStorage(key, value) {
    sessionStorage.setItem('grn_grid_session_'.concat(key), value);
  }

  getFromGRNSessionStorage(key) {
    return sessionStorage.getItem('grn_grid_session_'.concat(key)) || '';
  }

  addToGRNLocalStorage(key, value) {
    sessionStorage.setItem('grn_grid_local_'.concat(key), value);
  }

  getFromGRNLocalStorage(key) {
    return sessionStorage.getItem('grn_grid_local_'.concat(key)) || '';
  }

  tryFilterAllInventoryOptions() {
    if (this.allInventoryOptions?.length) {
      this.inventoryOptions = this.allInventoryOptions.filter((o) => o.isUserAssociated);
      return true;
    }

    // Nothing to filter
    return false;
  }

  async asyncSearchInventory() {
    if (this.tryFilterAllInventoryOptions()) {
      return Promise.resolve();
    }

    this.isInventoryOptionsLoading = true;

    this.allInventoryOptions = camelcaseKeys(await getAvailableInventories());
    this.inventoryOptions = this.allInventoryOptions.filter((o) => o.isUserAssociated);

    this.isInventoryOptionsLoading = false;
    return Promise.resolve();
  }

  debouncedSearchInventory = debounce(this.asyncSearchInventory, 75, { maxWait: 150 });
  debouncedSearchSupplier = debounce(this.asyncSearchSupplier, 250, { maxWait: 750 });

  onReportIntervalChange(val) {
    [this.startDate, this.endDate] = val;
    this.dateInterval = val;

    this.onApplyFilters();
  }

  hoveredRowIndex = -1;

  items: Array<any> = [];

  tableLoaded = false;
  tableBusy = false;

  itemsPerPage = 25;
  currentGridPage = 1;
  allItemsCount = 0;

  editItemsPerPage = this.itemsPerPage;

  badgeVariant(statusCode) {
    switch (statusCode) {
      case 'draft':
        return 'secondary';
      case 'processed':
        return 'success';
      case 'canceled':
        return 'warning';
      case 'synchronized':
        return 'primary';
      default:
        return 'secondary';
    }
  }

  async loadGridPage(page, perPage) {
    try {
      this.tableBusy = true;
      const listResult = camelcaseKeys(await getGoodsReceivedNotesList(page, perPage, {
        startDate: formatISO(this.dateInterval[0]),
        endDate: formatISO(this.dateInterval[1]),
        inventoryId: this.inventory?.inventoryId,
        supplierId: this?.supplier?.partnerId,
        statusCode: this?.status?.status,
      }));

      this.allItemsCount = listResult.count;
      this.items = listResult.items.map((x) => ({
        ...x,
        gestiune: '',
        statusCode: this.translateStatusCode(x.statusCode),
      }));
    } finally {
      this.tableBusy = false;
      this.tableLoaded = true;
    }
  }

  translateStatusCode(statusCode) {
    if (statusCode === 0) {
      return 'draft';
    }
    if (statusCode === 1) {
      return 'processed';
    }
    if (statusCode === 2) {
      return 'canceled';
    }
    if (statusCode === 3) {
      return 'synchronized';
    }
    return `${statusCode}`;
  }

  async initialize() {
    await this.loadGridPage(this.currentGridPage, this.itemsPerPage);
  }

  async onApplyFilters() {
    if (!this.editItemsPerPage) {
      this.editItemsPerPage = 25;
    }
    this.itemsPerPage = this.editItemsPerPage;
    this.storeFilters();
    await this.loadGridPage(this.currentGridPage, this.itemsPerPage);
  }

  async onApplyItemsPerPage() {
    if (!this.editItemsPerPage) {
      this.editItemsPerPage = 25;
    }
    this.itemsPerPage = this.editItemsPerPage;
    this.storeFilters();
    await this.loadGridPage(1, this.itemsPerPage);
  }

  async onCurrentGridPageChanged(page) {
    this.currentGridPage = page;
    await this.loadGridPage(this.currentGridPage, this.itemsPerPage);
  }

  storeFilters() {
    this.addToGRNSessionStorage('inventoryId', this.inventory?.inventoryId);
    this.addToGRNSessionStorage('startDate', formatISO(this.dateInterval[0]));
    this.addToGRNSessionStorage('endDate', formatISO(this.dateInterval[1]));
    this.addToGRNSessionStorage('supplier', this.supplier?.partnerId);
    this.addToGRNSessionStorage('status', this.status?.status);

    this.addToGRNLocalStorage('itemsPerPage', this.itemsPerPage);
  }

  rowHovered(_: unknown, index: number) {
    this.hoveredRowIndex = index;
  }

  rowUnhovered() {
    this.hoveredRowIndex = -1;
  }

  getStatusOptionObject(status) {
    return this.statusOptions.filter((option) => option.status === parseInt(status, 10))[0];
  }

  async created() {
    this.allInventoryOptions = camelcaseKeys(await getAvailableInventories());
    this.tryFilterAllInventoryOptions();
    // Try and select the saved inventory
    this.inventory = this.allInventoryOptions.find((opt) => opt.inventoryId === Number.parseInt(this.getFromGRNSessionStorage('inventoryId'), 10));

    if (this.getFromGRNSessionStorage('startDate') && this.getFromGRNSessionStorage('endDate')) {
      this.dateInterval = [new Date(Date.parse(this.getFromGRNSessionStorage('startDate'))), new Date(Date.parse(this.getFromGRNSessionStorage('endDate')))];
    }
    this.supplier = await lookupSupplierById(this.getFromGRNSessionStorage('supplier'));
    this.status = this.getStatusOptionObject(this.getFromGRNSessionStorage('status'));

    if (this.getFromGRNLocalStorage('itemsPerPage')) {
      this.itemsPerPage = parseInt(this.getFromGRNLocalStorage('itemsPerPage'), 10);
      this.editItemsPerPage = this.itemsPerPage;
    }
    this.initialize();
  }

  get parseJSON() { return parseJSON; }

  get fields() {
    return [
      {
        key: 'numar',
        label: i18n.t('nir.grid.numar'),
      },
      {
        key: 'data',
        label: i18n.t('nir.grid.data'),
      },
      {
        key: 'sourceDocument',
        label: i18n.t('nir.grid.sourceDocument'),
      },
      {
        key: 'furnizor',
        label: i18n.t('nir.grid.furnizor'),
      },
      {
        key: 'gestiune',
        label: i18n.t('nir.grid.inventories'),
      },
      {
        key: 'gestionar',
        label: i18n.t('nir.grid.receivedBy'),
      },
      {
        key: 'stare',
        label: i18n.t('nir.grid.stare'),
      },
      {
        key: 'value',
        label: 'Val. f. TVA',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
      {
        key: 'valueWithVat',
        label: 'Val. cu TVA',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
      {
        key: 'actiuni',
        label: i18n.t('nir.grid.actiuni'),
        thClass: 'col-action',
        tdClass: 'col-action-body',
      },
    ];
  }
}
